/* eslint-disable react-hooks/exhaustive-deps */
/**
 * LoginButton component.
 * @module components/ItaliaTheme/Header/HeaderSlim/LoginButton
 */

import React from 'react';
import { useLocation } from 'react-router-dom';
import { getBaseUrl } from '@plone/volto/helpers';

import { Button } from 'design-react-kit/dist/design-react-kit';

import config from '@plone/volto/registry';

const LoginButton = ({ children, size = 'full' }) => {
  const location = useLocation();

  const arLoginUrl = config.settings.siteProperties?.arLoginUrl;

  const loginURL = arLoginUrl
    ? `${getBaseUrl(location.pathname)}${arLoginUrl}`
    : `${getBaseUrl(location.pathname)}/login`;

  return (
    <Button
      className="btn-icon"
      color="inherit"
      href={loginURL}
      icon={false}
      size={size}
      tag="a"
    >
      {children}
    </Button>
  );
};

export default LoginButton;
